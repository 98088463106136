#breadcrumb {
  background-color: $bg-soft-orange;
  background-image: image-url('blog-singl-title-img-1.jpg');
  background-position: center 0;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1.5rem 0;
  margin-bottom: 1rem;

  h4 {
    font-size: 30px;
    line-height: 1.45;
    color: $primary;
    margin: 0;
    padding: 0;
  }

  a {
    color: $gray-medium;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  .breadcrumb-row {
    display: flex;
    justify-content: space-between; // left-right
    align-items: center; // vertical-align
  }

  .breadcrumb-item {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1.75;
    font-family: Raleway, sans-serif;
  }
}
