.strip {
  position: relative;
  background-color: $bg-soft-orange;
  margin: 2.5rem 0;
  display: flex; // contain content

  &.light { background-color: $bg-soft-orange; }
  &.dark { background-color: $bg-soft-pink; }

  @include media-breakpoint-up(md) {
    margin: 5rem 0;
  }

  z-index: 0; // lower then jumbotron or heading
}

.strip-content {
  margin: 1.5rem 0;  // any h2/p margins collapse with this.

  @include media-breakpoint-up(md) {
    margin: 3rem 0;
  }
}

.strip-shape {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;

  // TODO
  transform: rotate(180deg);
}

.strip-shape svg {
  left: 50%;
  position: relative;
  display: block;

  // TODO: make this obsolete
  transform: translateX(-25%);
  width: calc(220% + 1.3px);
  height: 20rem;
}

.strip-shape path {
  fill: #fff;
  overflow: hidden;
}
