.btn {
  &.btn-start {
    display: table;
    margin: 1rem auto 1rem 0;
  }

  &.btn-end {
    display: table;
    margin: 1rem 0 1rem auto;
  }

  &.btn-block {
    display: block;
    margin: 2rem 0;
  }
}
