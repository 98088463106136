#back-top-wrapper {
  position: fixed;
  right: 30px;
  bottom: 30px;
  overflow: hidden;
  z-index: 999;
}

#back-top {
  margin: 0;

  a {
    // square block
    display: block;
    width: 40px;
    height: 40px;

    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    font: 11px/100% Arial, Helvetica, sans-serif;
    transition: opacity 1s;
  }

  span {
    // content in the middle
    display: flex;
    width: 100%;
    height: 100%;

    background: $orange-light;
    border-radius: 2px;
    transition: padding-bottom .3s;

    &:hover {
      padding-bottom: 4px;
    }
  }

  svg {
    display: block;
    width: 12px;
    height: 12px;
    margin: auto auto;
  }

  path {
    fill: $orange;
  }
}
