#error_page {
  #main-content {
    text-align: center;
    padding-top: 2rem;

    @include media-breakpoint-up(lg) {
    padding-top: 7rem;
    }

  }

  h1 {
    color: $primary;
    margin: 2rem 0;
  }
}
