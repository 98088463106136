.quote {
  position: relative;
  margin: 2rem 0;
  padding: 7% 12%;
  background-color: $bg-soft-orange;
  font-size: $blockquote-font-size;
  font-weight: 300;
  line-height: 2rem;
  text-align: center;
}

.quote-logo {
  position: absolute;
  display: block;
  top: 25px;
  left: 25px;
  width: 56px;
  height: 56px;
  color: $orange-dark;
}

h3.quote-text {
  margin: 0;
}

.quote-author {
  position: relative;
  margin: 1rem 0 0;

  font-family: $headings-font-family;
  font-weight: 400;
  font-style: italic;
  font-size: 20px;
  line-height: 1.25em;
  color: $orange-dark;
}
