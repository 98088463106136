// All variables that are used throughout the CSS.
//
// This file also overrides Bootstrap variables.
// For a complete overview of all variables to override, see:
// * node_modules/bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss
// * https://github.com/twbs/bootstrap-sass/blob/master/assets/stylesheets/bootstrap/_variables.scss

// mixins (using webpack root)
$image-url-path: 'frontend/images/';
$fonts-url-path: 'frontend/fonts/';

$enable-dark-mode: false;

// Typography
$font-family-sans-serif: "Lato", sans-serif;
$font-size-base: 1.19rem;
$line-height-base: 1.7;

$blockquote-font-size: 2rem;
$small-font-size: .805rem;

$gray-light: #f0f0f4;
$gray-medium: #8d8d8d;
$gray-dark: #515151;
$gray-darker: #333;

$orange: #fb873c;
$orange-dark: #ff782b;
$orange-lighter: #ffdac5;
$orange-light: #ffe8db;
$bg-soft-orange: #fff9f4;

$lime: #62c2da;

$bg-soft-yellow: #f9f6f1;

$pink-dark: #878aec;
$pink-light: #f4f5fb;
$bg-soft-pink: #f5f5fd;

// Base colors
$body-bg: #fff;
$body-color: #4f4f4f;
//$body-emphasis-color: $black;
$primary: $orange;
$secondary: $pink-dark;

// Titles
$headings-font-family: "Cormorant Garamond", sans-serif;
$headings-font-weight: 300;
$headings-color: $gray-darker;

$link-color: $primary;
$link-hover-color: $gray-dark;

$container-max-widths: (
  // Full width on tablet and mobile:
  //sm: 540px,
  //md: 720px,
  lg: 960px,
  xl: 1140px
);

// Breadcrumb
$breadcrumb-bg: $gray-light;
$breadcrumb-padding-x: 0;
$breadcrumb-padding-y: 0;
$breadcrumb-margin-bottom: 0;
$breadcrumb-border-radius: 0;

// Navbar
$navbar-brand-font-size: 1.625rem; // 26px
$navbar-brand-padding-y: 1rem; // mobile

$navbar-light-color: $gray-darker;
$navbar-light-active-color: $orange;

$navbar-light-hover-color: $primary;
$navbar-light-brand-color: $primary;
$navbar-light-brand-hover-color: $orange-dark;

// Navbar links
//$nav-link-padding-y: .5rem;  // menu height on mobile
$x-nav-link-padding-md-y: 1.4rem;  // menu height
$nav-link-font-weight: bold;
$nav-link-font-size: 0.82rem;

// Navbar expand links
$navbar-nav-link-padding-x: 1.125rem;  // ~18px


//$navbar-padding-x: 0;
//$navbar-padding-y: 0;

// Header
$header-margin: 1rem;
$header-margin-md: 2rem;
$header-margin-lg: 3rem;
$header-margin-xl: 5rem;
$navbar-brand-padding-y: 0;

// Forms
$form-group-margin-bottom: 1.5rem;
$input-padding-x: 1.5rem;
$input-padding-y: 0.75rem;
$input-btn-padding-x: 1.5rem;
$input-btn-padding-y: 0.75rem;
$input-border-color: rgba(0, 0, 0, 0.2);
