#header {
  z-index: 10;

  // Header space from edge
  .container-fluid {
    margin: 0 $header-margin;
    padding: 0;

    @include media-breakpoint-only(md) {
      margin: 0 $header-margin-md;
    }

    @include media-breakpoint-only(lg) {
      margin: 0 $header-margin-lg;
    }

    @include media-breakpoint-up(xl) {
      margin: 0 $header-margin-xl;
    }
  }

  .nav-item {
    text-transform: uppercase;
  }
}

.navbar-toggler {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}

#header-content {
  // this keeps the header outside the collapse button
  // Group for 3 flex items: [ header-menu | header-logo | header-contact ]
  display: flex;
  flex-direction: row;
  min-width: 100%;
  flex-wrap: wrap;
  align-items: center; // vertical align
}

#header-logo {
  max-height: 8rem;
  min-height: 2em;
  line-height: 1.3;

  // when aligned next to other items, never be the largest picture
  min-width: 10%;
  max-width: 30%;

  img {
    width: auto;
    max-width: 100%;
    vertical-align: top;

    @include media-breakpoint-down(md) {
      max-height: 160px; // avoid huge scaling on tablet
    }
  }
}

#header-menu {
  letter-spacing: 0.1em;
  margin-right: auto;  // full empty space in flexbox

  @include media-breakpoint-up(md) {
    .nav-link {
      padding-top: $x-nav-link-padding-md-y;
      padding-bottom: $x-nav-link-padding-md-y;
    }
  }

  @include media-breakpoint-down(md) {
    order: 9;  // at end, so contact info doesn't move on expand
  }
}

#header-contact {
  letter-spacing: 0.125em;

  @include media-breakpoint-down(sm) {
    margin-left: auto;
    padding-right: 4rem; // don't cover menu button
  }

  address,
  p {
    font-size: 85%;
    line-height: 1.75;
    margin: 0 0 0.25rem 0;
    white-space: nowrap;
  }

  span {
    display: inline-block;
    vertical-align: top;
    margin-left: 5px;
    white-space: normal;
  }

  svg {
    display: inline-block;
    height: 1em;
    path {
      fill: $primary;
    }
  }
}
