.jumbotron {
  // Background can expand, resize larger then the image is.
  // Note that the 'fixed' background always scales relative to the width/height of the window!
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 25%;
  background-color: $bg-soft-orange;
  margin-bottom: 3rem;
  text-align: center;
  color: $white;

  // Page title effect
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;

  .container {
    display: inline-block;  // keep margins from content inside
  }

  h1,
  h2,
  h3,
  h4 {
    color: $white;
    text-shadow: 0 1px 1px #999;
  }

  h1 {
    font-size: 2.2rem;
    margin: 4rem 0 2rem;
    letter-spacing: 0;
  }

  p {
    font-size: 1.43rem;
    margin: 0 auto 1rem auto;
    font-weight: 300;
    text-shadow: 0 1px 1px #999;
  }

  .btn {
    margin: spacer(1) 0.4rem;
    text-shadow: none;
    // disable .btn-lg from template
    @include button-size(
      $btn-padding-y,
      $btn-padding-x,
      $font-size-base,
      $btn-border-radius
    );
  }

  @include media-breakpoint-down(sm) {
    .container {
      max-width: none;
    }
  }

  @include media-breakpoint-up(lg) {
    h1 {
      font-size: 4.25rem;
    }

    p {
      max-width: 43.3rem;
    }

    .btn {
      @include button-size(
        $btn-padding-y-lg,
        $btn-padding-x-lg,
        $font-size-lg,
        $btn-border-radius-lg
      );
    }
  }
}
