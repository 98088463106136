.strip + .heading,
.strip + a[name] + .heading {
  margin-top: -14.5rem;
}

.heading {
  text-align: center;
  position: relative;
  z-index: 2; // above strip

  h2 {
    color: $primary;
    font-size: 200px;
    line-height: 1em;
    padding: 0;
    margin: 0 0 1.5rem 0;

    // Show the letter, but avoid indexing it.
    &::before {
      content: attr(data-content);
      display: inline;
    }
  }

  h3 {
    text-transform: uppercase;
    margin: 0;
  }

  h3, h4 {
    font-size: 45px;
    padding: 0;
    line-height: 1;

    @include media-breakpoint-down(sm) {
      font-size: 35px;
    }
  }

  h4 {
    margin: 0 0 1rem 0;
  }
}

